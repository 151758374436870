import BanniereCustom from "./components/ej-banniere-custom.js";
import BanniereImg from "./components/banniereImage.js";
import BanniereLaterale from "./components/banniere-laterale";
import CacheContent from "./components/cacheContent";
import DetectBrowser from "./components/detectBrowser";
import EncapsuleurFiltre from "./components/ej-encapsuleur-filtre";
import ExpandCollapse from "./components/expand-collapse";
import owlCarouselWaitForClientConnectionInfo from "./components/ej-owl-carousel.js";
import UpdateNoticeYear from "./components/updateNoticeYear";
import ShareButton from "./components/shareButton.js";
import Tabs from "./components/tabs";
import trackVisitByDay from "./components/trackVisitByDay.js";
import { viewportInfoInit, breakpointIs } from "./components/viewport-info.js";
import "./components/position-sticky-polyfill.js";
import { handleMainMenu } from "./components/menus.js";
import EjNotification from "./components/ej-notification.js";
import EjNewsletter from "./components/ej-newsletter.js";
import EjPromotion from "./components/ej-promotion.js";
import HideElements from "./components/ej-hide-elements-by-url";
import WatchHeader from "./components/watch-header.js";
import CarouselSlickInit from "./components/carousel-slick.js";

import WaitForClientConnectionInfo from "./components/wait-for-clients-connection-info.js";

const carouselCacheContent = new CacheContent(".fn-carousel");

const domains = {
  LQ: ".lotoquebec",
  EJ: ".espacejeux",
};

document.addEventListener("jeuSidebarLoaded", function () {
  BanniereImg.init();
  // eslint-disable-next-line no-undef
  initBannieres();
});

document.addEventListener("DOMContentLoaded", function () {
  DetectBrowser.init();
  viewportInfoInit();
  handleMainMenu();
  ShareButton.init();
  UpdateNoticeYear.init();
  BanniereImg.init();
  trackVisitByDay();
  // eslint-disable-next-line no-undef
  EspacejeuxPortail.setLanguage();
  // eslint-disable-next-line no-undef
  initPastilleLotprogressif(EspacejeuxPortail.LANGUE);
  fixFirstClickScrollTop();
});

document.addEventListener("detectBrowser_is-initialized", function () {
  if (!DetectBrowser.isMagnoliaEditMode) {
    setTimeout(function () {
      carouselCacheContent.init();

      EncapsuleurFiltre.init();
      owlCarouselWaitForClientConnectionInfo();
      WatchHeader();
      // eslint-disable-next-line no-undef
      initPastilleLotprogressif(EspacejeuxPortail.LANGUE);
    }, 800);

    $(document).on("ejEventLogin", function () {
      $(".fn-carousel").owlCarousel("destroy");
      carouselCacheContent.refresh();
      EncapsuleurFiltre.reset();
      EncapsuleurFiltre.init();

      owlCarouselWaitForClientConnectionInfo();
      WatchHeader();
    });
  }
});

$(document).ready(() => {
  var params = new URL(document.location).searchParams;

  if (params.get("opl") === "1" && window.location.href.indexOf(domains.LQ) > 0) {
    var redirectUrl = window.location.href.replace(domains.LQ, domains.EJ);
    window.location.href = redirectUrl;
  }

  if ($(".ej-div-promotion-add")) {
    EjPromotion();
  }
  HideElements();
  // wait until images, carousel, etc. are loaded
  $('[data-component~="position-sticky-polyfill"]').delay(2000).positionStickyPolyfill();

  $('[data-component="bootstrap-collapse-show"]').on("click", function () {
    const anchor = $(this).attr("href");
    const $target = $($(anchor).find("[data-target]").attr("data-target"));

    $target.collapse("show");
  });

  BanniereCustom.init();
  $('[data-component="conteneurRangeeCarrouselMobile"]').conteneurRangeeCarrouselMobile();

  ExpandCollapse();
  Tabs();

  setTimeout(BanniereLaterale, 500);

  CarouselSlickInit();

  // eslint-disable-next-line no-undef
  initBannieres();

  EjNotification();

  // Create the "didomiOnReady" listener
  handleYoutubeConsent("c:youtube-xhBVKfkq"); // TODO: mettre vendorId youtube dans une variable globale

  $(".filterButtonMobile").on("click", function () {
    $(".searchButtonMobile").removeClass("selected");
    $(".filterButtonMobile").toggleClass("selected");
    $("#boite-recherche").hide();
    $(".filters-list-wrapper").fadeToggle("fast");
  });
  EjNewsletter();

  $(".searchButtonMobile").on("click", function () {
    $(".filterButtonMobile").removeClass("selected");
    $(".searchButtonMobile").toggleClass("selected");
    $(".filters-list-wrapper").toggle(false);

    if ($("#boite-recherche").css("display") !== "none") {
      $("#boite-recherche").slideToggle(false);
    } else {
      $("#boite-recherche").slideToggle(true);
    }
  });

  $(".moreInfoButtonMobile").on("click", function () {
    $(".moreInfoButtonMobile").toggleClass("selected");
    $(".text-content-detail").fadeToggle("slow");
  });

  //TODO: attacher ce code du lancement de jeu
  if (document.querySelector("#btn-ciam-auth0")) {
    if (
      (typeof isLoggedIn == "function" &&
        window.location.href.indexOf(domains.EJ) > 0 &&
        isLoggedIn()) ||
      // eslint-disable-next-line no-undef
      EspacejeuxPortail_Cookie.lireCookie("logged_in") === "1"
    ) {
      lancerJeux();
    }
  }

  if (window.location.pathname.indexOf("information") != -1 && window.location.search.length > 0) {
    $("a").attr("href", function (i, str) {
      return str + window.location.search;
    });
  }
  params = new URL(document.location).searchParams;
  let lang = params.get("lang");
  if (window.location.pathname.includes("/fr/evenements/") && lang === "en") {
    let nouvellePath = window.location.href.replace("fr", "en");
    window.location.href = nouvellePath;
  }
});

$(document).on("ejEventHeaderLoad", function () {
  try {
    // eslint-disable-next-line no-undef
    EspacejeuxPortail.init();

    /* TODO 2020-01-10 À SUPPRIMER CAR APPELLE LOGINNAV PLUSIEURS FOIS */

    $(".moreInfoButton").on("click", function () {
      $(".moreInfoButton").toggle();
      $(".text-content-detail").fadeToggle("slow");
    });

    /************************************************************************
     * Gère les messages de self-exclusion et de bloquage d'accès temporaire
     ************************************************************************/
    if ($(".displayWhenLogged").length) {
      // eslint-disable-next-line no-undef
      if (isLoggedIn()) {
        $(".displayWhenLogged").show();
        $(".hideWhenLogged").hide();
      } else {
        $(".displayWhenLogged").hide();
        $(".hideWhenLogged").show();
      }
    }

    if (typeof allerVersAncre == "function") {
      setTimeout(function () {
        // eslint-disable-next-line no-undef
        allerVersAncre({
          regularExp: /#(.*?)&_ga/g,
          uri: window.location.href,
        });
      }, 700);
    }
  } catch (error) {
    console.log("Erreur " + error.message);
  }
});

function lancerJeux() {
  let game = null;
  let game_mobile = window.sessionStorage.getItem("game_mobile");
  // Lancement Jeu Mobile
  if (game_mobile && game_mobile !== "null") {
    game = JSON.parse(game_mobile);
    // eslint-disable-next-line no-undef
    ejGameMobile.getGameLaunchUrl(
      game.gameName,
      game.fournisseur,
      game.channel,
      game.playMode,
      game.lang,
      game.referer === null ? "" : game.referer,
      game.largeurFenetre,
      game.hauteurFenetre,
      game.domainePublicLoteries,
      game.fournisseurDesactive,
      game.jeuDesactive,
      game.msgDesactivation === null ? "" : game.msgDesactivation,
      game.tableId,
      game.infoWindowType,
      game.infoWindowDevices,
      game.infoWindowBrowsers,
      game.infoWindowOS,
      game.infoWindowText
    );
  } else {
    let game_desktop = window.sessionStorage.getItem("game_desktop");
    // Lancement Jeu Desktop
    if (game_desktop && game_desktop !== "null") {
      game = JSON.parse(game_desktop);
      // eslint-disable-next-line no-undef
      launch_game_and_gaq(
        game.game_code,
        game.fournisseur,
        game.channel,
        game.mode,
        game.langue,
        game.format,
        game.width,
        game.height,
        game.fournisseurDesactive,
        game.jeuDesactive,
        game.txtDesactivation ?? "",
        game.origine ?? "",
        game.referer ?? "",
        game.lancementJeuBg ?? "",
        game.lightboxDesactive,
        game.titre,
        game.tableId
      );
    }
  }
}

function fixFirstClickScrollTop() {
  //this is a patch to fix the initial click on the page who make that page scroll to the top of the page
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  if (isChrome) {
    $(document).on("mousedown", function mouseDownHandler() {
      var scrolled = $(window).scrollTop();
      $("html,body").animate({ scrollTop: scrolled }, "slow");
      $(document).off("mousedown", mouseDownHandler);
    });
  }
}

function handleYoutubeConsent(vendorId) {
  // Create the "didomiOnReady" listener
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (Didomi) {
    // Subscribe to the vendor status : It triggers the listener each time the status is changed for this vendor.
    Didomi.getObservableOnUserConsentStatusForVendor(vendorId).subscribe(function (consentStatus) {
      // Check if the "consentStatus" is true (eg. the user agreed to the vendor & his purposes)
      if (consentStatus === true) {
        playVideoAndHideOverlay();
      } else {
        hidePlayerVideo();
      }
    });
    // An event listener is attached to each button element
    document.querySelectorAll(".video-consent-overlay-accept-button").forEach(function (button) {
      button.addEventListener("click", function () {
        // When the button is clicked, we call the setPositiveConsentStatusForVendor custom function to enable the vendor (Youtube) and all his purposes.
        setPositiveConsentStatusForVendor(vendorId);
      });
    });
  });
}

function playVideoAndHideOverlay() {
  document.querySelectorAll(".youtube-container").forEach(function (overlay) {
    var iframe = overlay.querySelector("iframe[data-src]");
    if (iframe) {
      var src = iframe.getAttribute("data-src");
      iframe.setAttribute("src", src);
      iframe.removeAttribute("data-src");
      overlay.querySelector(".video-consent-overlay").classList.add("d-none");
    }
  });
}

function hidePlayerVideo() {
  document.querySelectorAll(".youtube-container").forEach(function (overlay) {
    var iframe = overlay.querySelector("iframe[src]");
    if (iframe) {
      var src = iframe.getAttribute("src");
      iframe.setAttribute("data-src", src);
      iframe.removeAttribute("src");
      overlay.querySelector(".video-consent-overlay").classList.remove("d-none");
    }
  });
}

function setPositiveConsentStatusForVendor(vendorId) {
  // Get all the vendor purposes
  // eslint-disable-next-line no-undef
  var purposes = Didomi.getVendorById(vendorId).purposeIds;
  // Create a "transaction"...
  // eslint-disable-next-line no-undef
  var transaction = Didomi.openTransaction();
  // ... enable the vendor
  transaction.enableVendor(vendorId);
  // ... and all his purposes
  transaction.enablePurposes(...purposes);
  // update the new status using "commit"
  transaction.commit();
}

function gtmCustomEventByVendor(vendorId, eventObjToPush) {
  window.dataLayer = window.dataLayer || [];
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push(function (Didomi) {
    const consentStatus = Didomi.getUserStatus().vendors.global_consent.enabled.includes(vendorId);
    const vendorsWithKnownConsentStatus = Object.values(
      Didomi.getUserStatus().vendors.global_consent
    ).flat();
    if (consentStatus === true) {
      window.dataLayer.push(eventObjToPush);
    } else if (!vendorsWithKnownConsentStatus.includes(vendorId)) {
      Didomi.on("consent.changed", function () {
        if (Didomi.getUserStatus().vendors.global_consent.enabled.includes(vendorId) === true) {
          window.dataLayer.push(eventObjToPush);
        }
      });
    }
  });
}
